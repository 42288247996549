.post {
  .details {
    .meta {
      .tags {
        margin: 1% 0;

        .tag {
          display: inline-block;
          border: 1px solid $color-black;
          padding: 0.3rem;
          font-style: normal;
          margin: 1% 0;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &.bottom {
        text-align: right;
        padding-bottom: 3%;

        p {
          text-align: right;
        }
      }
    }
  }

  .highlight-text {
    background-color: $color-light-gray;
    border: $color-dark-gray solid 1px;
    text-align: justify;
    padding: 0 1em 1em 1em;
    white-space: pre-wrap;
    font-size: 14px;
    margin: 1.5em 0;
  }

  &__information {
    > div {
      text-align: right;
      font-style: italic;
      font-size: 0.9rem;
    }
  }

  h1 {
    text-align: center;
  }

  h4 {
    font-style: italic;
    text-align: justify;
  }

  p {
    margin-bottom: 1em;

    a {
      text-decoration: underline;
    }

  }

  .image, .video {
    img {
      cursor: pointer;
    }

    .caption {
      text-align: center;
      font-style: italic;
      color: $color-gray;
      margin-top: 2%;
    }
  }
}
