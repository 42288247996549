/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 101; /* Sit on top */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
    overflow: hidden;
}

/* Modal Content (Image) */
.modal-content {
    display: block;
    z-index: 102;
    max-width: 90%;
    max-height: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
.modal-caption {
    position: fixed;
    bottom: 5%;
    margin: 0 auto;
    display: block;
    width: 80%;
    padding-left: 10%;
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-white;
    z-index: 102;
    font-family: sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 500;
}


/* The Close Button */
.close-modal {
    position: fixed;
    top: 15px;
    right: 35px;
    font-size: 40px;
    font-weight: bold;
    transition: color 0.2s ease-in-out;
    color: $color-gray;

    &:hover,
    &:focus {
      color: $color-white;
      text-decoration: none;
      cursor: pointer;
    }
}


/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
    .modal-content {
        width: 100%;
    }
}
