// for the cube subpage
.stream {
  &-top-menu {
    border-bottom: 1px dotted $color-light-gray;
    padding: 1.25rem 0;

    &__row {
      padding: 0.5rem;

      a {
        padding: 0 1%;
        font-variant: small-caps;
        font-size: 20px;
        text-decoration: none;
        transition: transform 0.2s ease-in-out;
      }

      i, .fa-gamepad-alt, .fa-gamepad-modern {
        color: $color-light-gray;
        margin: 0.75% 0;
        font-size: 0.85rem;
      }
    }
  }
}
