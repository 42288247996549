.category, .tag {
  display: inline-block;
  position: relative;
  margin: 0 10px 10px 0;
  padding: 0 20px 0 23px;
  height: 26px;
  border-radius: 3px 0 0 3px;
  background: $color-lighter-gray;
  color: $color-gray;
  font-style: italic;
  font-size: 0.9rem;
  line-height: 26px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $color-main;
    color: $color-white;
  }
}


.category {
  &::after {
    right: 10px;
  }
  &::before {
    left: 0;
    border-right: 10px solid $color-lighter-gray;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    &::before {
      border-right-color: $color-main;
    }
  }
}

.tag {
  &::after {
    right: 0;
    border-left: 10px solid $color-lighter-gray;
    transition: all 0.3s ease-in-out;
  }
  &::before {
    left: 10px;
  }

  &:hover {
    &::after {
      border-left-color: $color-main;
    }
  }
}

.category::before, .tag::after {
  background: $color-white;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  top: 0;
}

.category::after, .tag::before {
  background: $color-white;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  width: 6px;
  position: absolute;
  top: 10px;
}
