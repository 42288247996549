h2 {
  margin: 1rem 0;
}

h3 {
  margin: 0.75rem 0;
}

h4 {
  margin: 0.25rem 0;
}

ul {
  margin: 1.25rem 0;
  list-style: disc;
  padding-inline-start: 40px;
}
