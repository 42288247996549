html {
  background: $color-lightest-gray;
  color: $color-darkest-gray;
  font-family: sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  min-width: 500px;
  min-height: 500px;
  box-sizing: border-box;

  /* Standard syntax */
  // scrollbar jump trick
  // TODO find better solution
  // overflow-x: scroll;
  overflow-y: scroll;
}

body {
  margin: 3rem;
  padding: 2rem;
  min-width: 500px;
  min-height: 500px;
  background: $color-white;
  border-radius: .5rem;
  border: 6px $color-lighter-gray solid;
}

*, *:before, *:after {
  box-sizing: inherit;
}
