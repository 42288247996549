img {
  max-width: 50%;
  display: block;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}

iframe {
  max-width: 80%;
  display: block;
  margin: 0 auto;
  margin-bottom: 3%;
}
