@font-face {
  font-family: 'cube';
  src:url('../../assets/fonts/Cube.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Permanent Marker';
  src:url('../../assets/fonts/PermanentMarker-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// colors
.border-main-color {
  border-color: $color-main;
}
.text-main-color {
  color: $color-main;
}

h1, h2, h3, h4, h5, h6 {
  color: $color-darkest-gray;
  font-weight: 400;
}

i.list-i {
  color: $color-black;
}

// transform is only valid for
// display: block a elements
// for example inside a flex div
a {
  color: $color-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// default aligment
p {
  text-align: justify;
}

.font-cursive {
  font-family: 'Permanent Marker', cursive;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.83em;
}

h6 {
  font-size: 0.67em;
}