.badgeList {
  padding: 10px 15px 0;

  .badge {
    min-height: 50px;
    max-height: 150px;
    max-width: 100px;
    margin: 1rem 0.5rem;

    filter: brightness(25%);
    -webkit-filter: brightness(25%);

    &.own {
      filter: brightness(100%);
    -webkit-filter: brightness(100%);
    }
  }
}

#regionSelector {
  margin: 50px;
}