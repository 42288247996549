.highlight {
  margin: 1.75em;

  // Name.Builtin.Pseudo
  .bp {
    color: #999999;
  }

  // Comment
  // Comment.Single
  // Comment.Multiline
  .c, .c1, .cm {
    color: #999988;
    font-style: italic;
  }

  // Comment.Preproc
  .cp {
    color: #999999;
    font-weight: bold;
  }

  // Comment.Special
  .cs {
    color: #999999;
    font-weight: bold;
    font-style: italic;
  }

  // Error
  .err {
    color: #a61717;
  }

  // Generic.Deleted
  // Generic.Deleted.Specific
  .gd {
    color: #000000;
    background-color: #ffdddd;

    .x {
      color: #000000;
      background-color: #ffaaaa;
    }
  }

  // Generic.Emph
  .ge {
    font-style: italic;
  }

  // Generic.Heading
  .gh {
    color: #999999;
  }

  // Generic.Inserted
  // Generic.Inserted.Specific
  .gi {
    color: #000000;
    background-color: #ddffdd;

    .x {
      color: #000000;
      background-color: #aaffaa;
    }
  }

  // Generic.Output
  .go {
    color: #888888;
  }

  // Generic.Prompt
  .gp {
    color: #555555;
  }

  // Generic.Error
  .gr {
    color: #aa0000;
  }

  // Generic.Strong
  .gs {
    font-weight: bold;
  }

  // Generic.Traceback
  .gt {
    color: #aa0000;
  }

  // Generic.Subheading
  .gu {
    color: #aaaaaa;
  }

  // Literal.Number.Integer.Long
  .il {
    color: #009999;
  }

  // Keyword
  // Keyword.Constant
  // Keyword.Declaration
  // Keyword.Pseudo
  // Keyword.Reserved
  .k, .kc, .kd, .kp, .kr {
    font-weight: bold;
  }

  // Keyword.Type
  .kt {
    color: #445588;
    font-weight: bold;
  }

  // Line number
  .lineno {
    color: rgba(27,31,35,0.3);
  }

  // Literal.Number
  // Literal.Number.Float
  // Literal.Number.Hex
  // Literal.Number.Integer
  // Literal.Number.Oct
  .m, .mf, .mh, .mi, .mo {
    color: #009999;
  }

  // Name.Attribute
  .na {
    color: #008080;
  }

  // Name.Builtin
  .nb {
    color: #0086B3;
  }

  // Name.Class
  .nc {
    color: #445588;
    font-weight: bold;
  }

  // Name.Exception
  // Name.Function
  .ne, .nf {
    color: #990000;
    font-weight: bold;
  }

  // Name.Entity
  .ni {
    color: #800080;
  }

  // Name.Namespace
  .nn {
    color: #555555;
  }

  // Name.Constant
  .no {
    color: #008080;
  }

  // Name.Tag
  .nt {
    color: #000080;
  }

  // Name.Variable
  .nv {
    color: #008080;
  }

  // Operator
  // Operator.Word
  .o, .ow {
    font-weight: bold;
  }

  // Literal.String
  // Literal.String.Single
  // Literal.String.Double
  // Literal.String.Backtick
  // Literal.String.Char
  // Literal.String.Doc
  // Literal.String.Escape
  // Literal.String.Heredoc
  // Literal.String.Interpol
  .s, .s1, .s2, .sb, .sc, .sd, .se, .sh, .si {
    color: #d14;
  }

  // Literal.String.Regex
  .sr {
    color: #009926;
  }

  // Literal.String.Symbol
  .ss {
    color: #990073;
  }

  // Literal.String.Other
  .sx {
    color: #d14;
  }

  // Name.Variable.Class
  // Name.Variable.Global
  // Name.Variable.Instance
  .vc, .vg, .vi {
    color: #008080;
  }

  // Text.Whitespace
  .w {
    color: #bbbbbb;
  }

  pre {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  tr {
    background: white !important;
  }

  table {
    text-align: start;
  }
}

// disable line numbers
// as it messes up mobile view
@media screen and (max-width: 1100px) {
  .gutter.gl {
    display: none;
  }
}
