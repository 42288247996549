.about {
  &__image {
    max-width: 33%;

    .image-content {
      max-width: 100%;
    }
  }

  &__intro {
    min-width: 60%;
    padding: 3%;

    p {
      margin: 3% 0;
    }
  }


}
