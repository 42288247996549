a, button, summary {
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

a, button, summary, input {
  &:focus {
    outline-width: 1px;
    outline-style: dotted;
    outline-color: $color-main;
  }
}

hr {
  border: none;
  height: 1px;
  background-color: $color-lighter-gray;
  color: $color-lighter-gray;
}
