// for the cube subpage
.cube {
  &-top-menu {
    border-bottom: 1px dotted $color-light-gray;
    padding: 1.25rem 0;

    &__row {
      padding: 0.5rem;

      a {
        padding: 0 1%;
        font-variant: small-caps;
        font-size: 20px;
        text-decoration: none;
        transition: transform 0.2s ease-in-out;
      }

      i, .fa-cube {
        color: $color-light-gray;
        margin: 0.75% 0;
        font-size: 0.85rem;
      }
    }
  }

  &-left-menu {
    min-width: 200px;
    padding-right: 5%;

    a {
      text-decoration: none;

      &::before {
        content: '•';
        // to avoid underline on hover
        display: inline-block;
        padding: 4%;
        font-size: 10px;
        color: $color-light-gray;
      }
    }
  }
}

.alg {
  &-case:not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 1px dashed $color-light-gray;
  }

  &-font,
  &-img {
    &.rotate {
      &-90 {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      &-180 {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      &-270 {
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
      }
    }
  }

  &-font {
    display: inline-block;
    height: 150px;
    width: 150px;
    margin: 0 auto;

    .letters {
      margin-left: 5%;

      div {
        position: absolute;
        width: 150px;
        height: 150px;
        font-family: 'cube';
        font-weight: normal;
        text-align: center;
        font-size: 130px;
        color: $color-black;
        opacity: 1;

        &.bg {
          opacity: 0.15;
        }
      }
    }
  }

  &-img {
    display: inline-block;
    width: 150px;
    height: 150px;

    img {
      text-align: center;
      padding-top: 25%;
    }
  }

  &-shower {
    display: inline-block;
    padding: 0 1%;
    width: 48%;
    min-width: 320px;

    .roofpig {
      width: 150px;
      height: 150px;
      margin: 20px auto;
      display: inline-block;
    }

    table {
      margin: 20px 0;
      width: 100% !important;
      max-width: 100%;

      tbody {
        tr {
          td {
            height: 50px;

            &:first-child {
              font-weight: 600;
              width: 80px;
            }
          }
        }
      }
    }
  }
}
