header, footer {
  width: 100%;

  nav {
    border-color: $color-lighter-gray;
    padding: 1.5rem 0;
    border-style: dotted;

    a {
      color: $color-light-black;
      text-decoration: none;

      &:hover {
        color: $color-main;
      }
    }
  }

  i {
    color: $color-main;
  }
}

header {
  nav {
    border-bottom-width: 2px;

    a {
      margin-right: 3rem;
      font-size: 1.4rem;
      font-variant: all-small-caps;
    }
  }
}

footer {
  nav {
    border-top-width: 2px;

    a {
      margin-right: 1rem;
    }
  }
}
