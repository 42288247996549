// for the font-map
.font-map-group {
  &:not(:last-child) {
    margin-bottom: 2%;
    border-bottom: 1px dashed $color-light-gray;
  }

  .character {
    &-list label {
      margin-right: 30px;
      font-size: 40px;
      width: 50px;
      text-align: right;
      font-family: arial;
      display: inline-block;
    }

    &-item {
      display: inline-block;
      width: 120px;
      height: 120px;

      div {
        position: absolute;
        font-family: 'cube';
        text-align: center;
        width: 120px;
        height: 120px;
        font-size: 120px;

        &.bg {
          opacity: 0.1;
        }
      }
    }
  }
}
