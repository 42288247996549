.post-list {
  list-style: none;

  li:last-child {
    border-bottom: none;
  }

  &--columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-break-inside: avoid;
    /* Chrome, Safari */
    page-break-inside: avoid;
    /* Theoretically FF 20+ */
    break-inside: avoid-column;
    /* IE 11 */
    li {
      -webkit-column-break-inside: avoid;
      /* Chrome, Safari */
      page-break-inside: avoid;
      /* Theoretically FF 20+ */
      break-inside: avoid-column;
      /* IE 11 */
      display: table;
      /* Theoretically FF 58+ */
    }
  }
}

.pagination {
  list-style: none;

  &__goto {
    margin-bottom: 1.25rem;

    input {
      border: 1px solid $color-black;
      padding: 0.25rem;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      width: 3.25rem;

      &:focus {
        border-color: $color-main;
        outline: none;
      }
    }

    button {
      background-color: $color-main;
      color: white;
      padding: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      transition: all 0.3s ease-in-out;
      opacity: 0.9;

      &:focus, &:hover {
        outline: none;
        opacity: 1;
      }

      &:disabled,
      &[disabled] {
        opacity: 1;
        background-color: $color-white;
        pointer-events: none;
        color: $color-light-gray;
      }

    }
  }

  &__link {
    text-decoration: none;
    color: $color-dark-gray;
    transition: all 0.3s ease-in-out;

    &box {
      background-color: $color-white;
      border: 0.5px solid $color-lighter-gray;
      border-collapse: collapse;
      padding: 0.25rem;
      transition: all 0.3s ease-in-out;

      &:first-child {
        border-top-left-radius: .75rem;
        border-bottom-left-radius: .75rem;
      }

      &:last-child {
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
      }

      &:hover {
        a {
          color: $color-main;
        }
      }

      &.selected {
        background-color: $color-main;
        pointer-events: none;
        a {
          color: $color-white;
        }

        &.pagination__link-jump {
          background-color: $color-white;
          a {
            color: $color-light-gray;
          }
        }
      }
    }
  }
}
