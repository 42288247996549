table {
  border-collapse: collapse;
  font-size: 14px;
  text-align: center;
  margin: 1.75em auto;
  max-width: 90%;
  color: $color-black;

  tr, th, td {
    padding: 0.5em;
    font-size: 14px;
    border: $color-main solid 1px;
  }

  td {
    min-width: 65px;
  }

  thead th {
    background-color: $color-main;

    &:not(:first-child) {
      border-left-color: $color-black;
    }
    &:not(:last-child) {
      border-right-color: $color-black;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background: $color-lighter-gray;
      }
      &:nth-child(odd) {
        background: $color-light-gray;
      }

      &:hover {
        background: $color-gray;
      }
    }
  }
}
