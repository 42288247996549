.content {
  width: 95%;
  min-width: 700px;
  max-width: 1200px;

  h1 {
    text-align: center;
  }
}

.page-content {
  p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  details {
    padding: 0.5rem;

    summary {
      transition: transform 0.3s ease-in-out;
      color: $color-black;
      cursor: pointer;
      margin-bottom: 0.5rem;
    }
  }
}
